import style from './BindBankcard.module.css'
import {observer} from "mobx-react-lite";

import app from '../../App.module.css';
import {Button, CircularProgress} from "@mui/material";
import {makeAutoObservable, runInAction} from "mobx";
import {useState} from "react";

import success from '../../assets/images/bind_bankcard_success.png'
import {useSearchParams} from "react-router-dom";
import GetCodeView from "../../components/get-code/GetCodeView";
import {applyBindBankCard, bindBankCard, CODE_SUCCESS, getRealName, LoginUserHeader} from "../../api";
import toast from "react-hot-toast";


enum DialogStatus {
    Hide,
    Success,
    Exist
}

class BandBankcardViewModel {

    name: string | undefined = ''
    bankCard: string = ''
    bankName: string = ''
    mobile: string = ''
    code: string = ''
    // showErrorDialog: boolean = false
    signSeqNo: string = ''
    dialogStatus: DialogStatus = DialogStatus.Hide


    loading: boolean = false

    header: LoginUserHeader


    constructor(header: LoginUserHeader) {
        this.header = header
        makeAutoObservable(this)

        this.loadReamName()
    }

    loadReamName() {
        this.name = ''
        getRealName(this.header).then(value => {
            runInAction(() => {
                if (value.code === CODE_SUCCESS) {
                    this.name = value.result!.fullName
                } else {
                    toast(value.message)
                    this.name = undefined
                }
            })
        })
    }

    dialogTitle() {
        switch (this.dialogStatus) {
            case DialogStatus.Success:
                return '银行卡绑定成功!'
            case DialogStatus.Exist:
                return '提示!'
            default:
                return ''
        }
    }

    dialogContent() {
        switch (this.dialogStatus) {
            case DialogStatus.Success:
                return '您的银行卡已成功绑定啦！'
            case DialogStatus.Exist:
                return '经验证，您之前已成功绑定过银行卡，流程全部通过！'
            default:
                return ''
        }
    }

    showExistBackCardDialog() {
        this.dialogStatus = DialogStatus.Exist
    }

    /**
     * 提交
     */
    async commit(userId: string, header: LoginUserHeader) {
        this.loading = true
        const result = await bindBankCard({
            userid: userId,
            bankcard: this.bankCard,
            bankName: this.bankName,
            tel: this.mobile,
            smsCode: this.code,
            signSeqNo: this.signSeqNo,
            accountName: this.name!
        }, header)

        runInAction(() => {
            this.loading = false
        })

        if (result.code === CODE_SUCCESS) {
            runInAction(() => {
                this.dialogStatus = DialogStatus.Success
            })
        } else {
            toast(result.message)
        }
    }

}

interface DialogProps {
    image: string,
    title: string,
    content: string,
    onConfirm: () => void,
}

function BindBankcard() {
    window.document.title = '绑定银行卡'

    const [params] = useSearchParams()
    const uuid = params.get('uuid') ?? ''
    const userName = params.get('userName') ?? ''
    const merchno = params.get('merchno') ?? ''
    const userId = params.get('userId') ?? ''

    const userHeader = {
        uuid: uuid,
        userName: userName,
        merchno: merchno
    }


    const [viewModel] = useState(() => new BandBankcardViewModel(userHeader))

    if (viewModel.name === '') {
        return <div className={style.container}>
            <CircularProgress/>
        </div>
    }

    if (viewModel.name === undefined) {
        return <div className={style.container}>
            <Button variant={'outlined'} onClick={() => {
                viewModel.loadReamName()
            }}>获取姓名失败，点我重试</Button>
        </div>
    }

    return <div style={{}}>
        <div className={style.root}>

            <div className={style.content}>

                <div className={style.row}>
                    <div className={style.title} style={{flexGrow: 1}}>账户姓名</div>
                    <div className={style.title}>{viewModel.name}</div>
                </div>

                <div className={style.divider} style={{
                    marginTop: '5vw'
                }}/>

                <div className={style.row} style={{
                    marginTop: '5vw'
                }}>
                    <div className={style.title}>银行卡账号</div>
                    <input className={style.input} placeholder={'请填写银行卡账号'} onChange={(event) => {
                        viewModel.bankCard = event.target.value
                    }} inputMode={"numeric"}
                           style={{flexGrow: 1}}></input>
                </div>

                <div className={style.divider} style={{
                    marginTop: '5vw'
                }}/>

                <div className={style.row} style={{
                    marginTop: '5vw'
                }}>
                    <div className={style.title}>开户银行</div>
                    <input className={style.input} placeholder={'请填写开户银行'} inputMode={"text"}
                           style={{flexGrow: 1}} onChange={(event) => {
                        viewModel.bankName = event.target.value
                    }}></input>
                </div>

                <div className={style.divider} style={{
                    marginTop: '5vw'
                }}/>

                <div className={style.title} style={{
                    marginTop: '3vw'
                }}>手机号码
                </div>

                <input onChange={(event) => {
                    viewModel.mobile = event.target.value
                }} className={app.phoneInput} inputMode={'tel'} placeholder={'请输入银行预留的手机号码'}/>

                <div className={app.codeContainer}>
                    <input onChange={(event) => {
                        viewModel.code = event.target.value
                    }} className={app.codeInput} placeholder={'请输入验证码'} inputMode={'numeric'}/>

                    <GetCodeView phone={viewModel.mobile} variant={'contained'} method={async () => {
                        let result = await applyBindBankCard({
                            userid: userId,
                            bankcard: viewModel.bankCard,
                            tel: viewModel.mobile
                        }, userHeader)

                        if (result.result === 'exist') {
                            //已经绑过卡了，直接完成
                            viewModel.showExistBackCardDialog()
                        }
                        return result
                    }} callback={(value) => {
                        viewModel.signSeqNo = value
                    }}/>
                </div>
            </div>

            <div style={{flexGrow: 1}}/>

            <div style={{
                backgroundColor: 'white',
            }}>
                <Button style={{
                    width: '92vw',
                    margin: '4vw'
                }} variant={'contained'}
                        disabled={viewModel.loading}
                        onClick={() => {
                            // viewModel.showErrorDialogFunc()
                            viewModel.commit(userId, userHeader).then()
                        }}
                >提交</Button>
            </div>

        </div>


        {


            viewModel.dialogStatus !== DialogStatus.Hide && <div className={style.dialog}>
                <Dialog image={success} title={viewModel.dialogTitle()} content={viewModel.dialogContent()}
                        onConfirm={() => {
                            // viewModel.showErrorDialog = false
                            // toast('结束h5页面并让原生跳转')

                            // @ts-ignore
                            window.flutter_inappwebview.callHandler('onWebViewClose');
                        }}/>
            </div>
        }
    </div>
}


function Dialog(props: DialogProps) {
    return <div className={style.dialogContainer}>

        <img src={props.image} className={style.dialogHeader} alt={''}/>

        <div className={style.dialogTitle}>{props.title}</div>

        <div className={style.dialogContent}>{props.content}</div>

        <div className={style.dialogButton}>
            <Button style={{width: '100%'}} onClick={props.onConfirm} variant={'contained'}>好的</Button>

        </div>

    </div>
}

export default observer(BindBankcard)
