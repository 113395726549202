import {Collapse, List} from "antd-mobile";
import React, {useState} from "react";
import style from "./NewbieGuide.module.css";
import {useNavigate} from "react-router-dom";

const NewbieGuide = () => {
    window.document.title = '新手指南'

    const navigate = useNavigate()

    const guideList = [
        {
            title: "商户进件流程图",
            url: ""
        },
        {
            title: "开通微信流程图",
            url: ""
        },
        {
            title: "开通支付宝流程图",
            url: ""
        },
        {
            title: "限额表",
            url: ""
        },
        {
            title: "特惠交易限额表",
            url: ""
        },
    ];
    const questionMenu = [{
        title: "机具问题",
        menu: [{
            title: "商户结算卡更改",
            content: "<联动Pay>APP-首页-卡包管理-更换结算卡-点击进行更改。"
        }, {
            title: "机器签到",
            content: "机器操作员号：01，密码：0000"
        }, {
            title: "操作特惠交易",
            content: `<联动Pay>APP-首页-卡包管理，添加交易卡后，在机器上选择VIP消费，用已绑定的交易卡操作交易。
      （可点击页面下方“<支持的银行卡及额度>”进行查询哪些银行支持特惠交易）`
        }, {
            title: "操作磁条卡认证",
            content: `<联动Pay>APP首页-磁条卡认证，进入提交银行卡信息进行认证。`
        }, {
            title: "机器关闭按键音",
            content: `
      品牌：锦弘霖   型号：HM600
      银联界面-确定-5管理-1签到-2操作员签到-操作员号：99，密码：12345678
      选择6系统参数设置-一直按确定键下翻，到设置日期页面再点击取消-是否打开按键蜂鸣，选择0否，再点击返回即可。

      品牌：天喻，型号：TP10
      银联界面-确定-4管理-1签到-2操作员签到-操作员：99，密码：12345678
      选择3系统参数设置，点击字母键向下翻页，找到是否关闭按键音，点击确认键进行编辑，再选择1【是】后，按确定键完成设置。

      品牌：安付，型号：AF70
      银联界面-点击菜单-5其他设置-6声音设置-选择2关闭。`
        },]
    }, {
        title: "交易问题",
        menu: [{
            title: "开通微信和支付宝收款功能",
            content: `进件成功后，<联动Pay>APP首页-扫码功能，点击开通，并扫码操作实名认证及授权。（可在公众号<UMF联动Pay>查看详细操作认证流程）`
        }, {
            title: "如何进行插卡交易",
            content: `机器银联界面-确定-4其他-1插卡消费。`
        }, {
            title: "交易失败返回码",
            content: `
      1、提示：LA-风控效验不通过
      机器所在地区禁止交易
      2、提示：Y3-交易失败
      未开通微信/支付宝扫码功能，或已开通但未操作实名认证并授权（为确保交易正常，请授权所有子商户号）。
      <可在公众号“UMF联动Pay”查看详细认证流程>
      3、提示：D1-磁条卡请先认证
      贷记卡磁条卡交易金额大于5000，需先认证。
      <联动Pay>APP首页-磁条卡认证，进入提交银行卡信息进行认证。`
        },
            {
                title: "交易额度如何提高",
                content: `进件成功后，在<联动Pay>商户APP-首页-点击页面上方的“立即升级”，输入商户本人信用卡号去提交认证。（升级为高级商户后可提升单日交易额度）如页面上方未有提示升级，则已是高级商户，不需要再操作认证。`
            }, {
                title: "机具开机返回码",
                content: `
      1、提示：Q3-终端商户不匹配
      机器有解绑过重新绑定了新的商户，按照以下流程操作后即可正常交易使用：
      锦弘霖HM600:机器签到页面-操作员99，密码12345678，选择8参数下载
      天喻TP10：操作员99，密码12345678，其他功能-恢复出厂设置，密码123456或者888888。
      安付AF70:在主界面或签到界面按F2或者取消键,选择2TMS更新，在TMS软件下载界面按0，输入密码257123，选择1重新创建文件系统，等待设备重启即可。

      2、提示：N2-商户非正式或正在审核
      机器未绑定商户，绑定商户后就可以正常使用。`
            }, {
                title: "如何设置打印一张小票",
                content: `品牌：安付，型号：AF70
      打印一张小票：银联界面-确定-4管理-1签到-2操作员签到，操作员号99-密码11229865。
      3交易功能设置-4打印张数-输入打印联数：1，点击确定。`
            }]
    }, {
        title: "关于商户",
        menu: [
            {
                title: "磁条卡认证",
                content: `<联动Pay>APP首页-磁条卡认证，进入提交银行卡信息进行认证。`
            }, {
                title: "入网年龄限制",
                content: `入网年龄：18-70周岁`
            }, {
                title: "不支持的交易地区",
                content: `青海、西藏、云南中缅边境（镇康县、耿马傣族佤族自治县、沧源佤族自治县、江城哈尼族彝族自治县、孟连傣族拉祜族佤族自治县、澜沧拉祜族自治县、西盟佤族自治县、腾冲市、龙陵县、景洪市、勐海县、勐腊县、芒市、盈江县、陇川县、瑞丽市、金平苗族瑶族傣族自治县、绿春县、河口瑶族自治县、泸水市、福贡县、贡山独龙族怒族自治县）
    以上地区交易不支持`
            }, {
                title: "刷卡提额",
                content: `进件成功后，在<联动Pay>商户APP-首页-点击页面上方的“立即升级”，输入商户本人信用卡号去提交认证。（升级为高级商户后可提升单日交易额度）如页面上方未有提示升级，则已是高级商户，不需要再操作认证。`
            },
        ]
    }];
    const [selectMenuIndex, setSelectMenuIndex] = useState<number>(0);
    console.log(questionMenu[selectMenuIndex]);
    const handleToDetail = (index: number) => {
        // history.push(`/newbie-guide-detail?page=${index + 1}`);
        navigate(`/newbie-guide-detail?index=${index + 1}`)
    };
    return (
        <div>
            <div className={style.lineBar}>
                操作指南
            </div>
            <List>
                {
                    guideList.map((item, index) => (
                        <List.Item key={item.title} clickable onClick={() => handleToDetail(index)}>
                            {item.title}
                        </List.Item>
                    ))
                }
            </List>

            <div className={style.lineBar}>
                常见问题
            </div>
            <div className={style.tabLine}>
                {
                    questionMenu.map((item, index) => (
                        <div className={style.tag + " " + (selectMenuIndex === index ? style.selectTag : "")}
                             onClick={() => setSelectMenuIndex(index)} key={item.title}>{item.title}</div>
                    ))
                }

            </div>
            <div>
                <Collapse>
                    {
                        questionMenu[selectMenuIndex].menu.map((item) => (
                            <Collapse.Panel key={item.title} title={item.title}>{item.content}</Collapse.Panel>
                        ))
                    }
                </Collapse>
            </div>
        </div>
    );
};
export default NewbieGuide;
