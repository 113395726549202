import {observer} from "mobx-react-lite";
import style from "./WithdrawAuthenticationPhone.module.css";
import logo from '../../assets/images/withdraw_authentication_phone_logo.png'
import {Button, CircularProgress} from "@mui/material";
import GetCodeView from "../../components/get-code/GetCodeView";
import {useNavigate, useSearchParams} from "react-router-dom";
import {makeAutoObservable, runInAction} from "mobx";
import {CODE_SUCCESS, cxtRegister, LoginUserHeader, sendCxtSmsCode} from "../../api";
import {useRef, useState} from "react";
import toast from "react-hot-toast";

class ViewModel {
    smsSeqNo: string = ''
    code: string = ''

    loading: boolean = false

    constructor() {
        makeAutoObservable(this)
    }

    /**
     * 提交
     * @param success 成功的回调
     * @param userHeader 用户信息头部
     */
    commit(success: () => void, userHeader: LoginUserHeader) {
        this.loading = true

        cxtRegister(userHeader.userName, this.code, this.smsSeqNo, userHeader).then(value => {
            runInAction(() => {
                this.loading = false
            })

            if (value.code === CODE_SUCCESS) {
                success()
            } else {
                toast(value.message)
            }
        })
    }


}

function WithdrawAuthenticationPhone() {


    const [params] = useSearchParams()
    const uuid = params.get('uuid') ?? ''
    const userName = params.get('userName') ?? ''
    const merchno = params.get('merchno') ?? ''

    const navigate = useNavigate()


    const inputRef = useRef(null);
    const userHeader = {
        uuid: uuid,
        userName: userName,
        merchno: merchno
    }

    const [viewModel] = useState(() => new ViewModel())

    window.document.title = '短信身份认证'
    return (
        <div className={style.root}>
            <div className={style.title}>
                正在为您进行提现身份认证
            </div>
            <div className={style.subTitle}>
                为了您的资金安全，提现操作需要通过身份认证。
            </div>

            <img src={logo} className={style.logo} alt={''}/>

            <div className={style.titlePhone}>
                手机号码
            </div>

            <div className={style.phone}>
                {userName}
            </div>

            <div className={style.codeContainer}>
                <input ref={inputRef} disabled={viewModel.loading} className={style.code} onChange={(event) => {
                    viewModel.code = event.target.value
                }} inputMode={'numeric'} placeholder={'请输入验证码'}/>

                <GetCodeView callback={(value) => {
                    viewModel.smsSeqNo = value
                }} phone={userName} variant={'contained'} method={async () => {
                    // @ts-ignore
                    inputRef.current.focus()
                    return await sendCxtSmsCode(userName, userHeader)
                }}/>
            </div>

            {
                <CircularProgress/> && viewModel.loading
            }


            <div style={{flexGrow: 1}}/>


            <Button onClick={() => {
                viewModel.commit(() => {
                    navigate(-1)
                }, userHeader)
            }} disabled={viewModel.loading} variant={'contained'} className={style.button}>提交</Button>

        </div>
    )
}

export default observer(WithdrawAuthenticationPhone)
