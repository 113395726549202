import React from 'react';
import {Route, Routes, useNavigate} from "react-router-dom";
import RegisterPage from "./pages/RegisterPage";
import AboutUS from "./pages/about-us";
import ArticleAgreement from "./pages/article-agreement";
import CertSuccess from "./pages/cert-success";
import NewbieGuide from "./pages/newbie-guide/NewbieGuide";
import NewbieGuideDetail from "./pages/newbie-guide-detail";
import PolicyDistribution from "./pages/policy-distribution";
import SettleHelp from "./pages/settle-help";
import DownloadAppPage from "./pages/download-app";
import RegisterSuccessPage from "./pages/register-success";
import WithdrawAuthenticationMain from "./pages/withdraw-authentication-main";
import {List} from "antd-mobile";
import {ListItem} from "@mui/material";
import WithdrawAuthenticationPhone from "./pages/withdraw-authentication-phone/WithdrawAuthenticationPhone";
import WithdrawAuthenticationId from "./pages/withdraw-authentication-id/WithdrawAuthenticationId";
import BindBankcard from "./pages/bind-bankcard/BindBankcard";
import {Toaster} from "react-hot-toast";
import CallbackPage from "./pages/callback-page/CallbackPage";
import Zj from "./pages/zj";
import ZjSuccessPage from "./pages/zj-success";
import ZjQueryPage from "./pages/zj-query";

function App() {
    return (
        <div >

            <Routes>
                <Route path={'/'} element={<Root/>}></Route>
                <Route path={'download-app'} element={<DownloadAppPage/>}></Route>
                <Route path={'register'} element={<RegisterPage/>}></Route>
                <Route path={'about-me'} element={<AboutUS/>}></Route>
                <Route path={'article-agreement'} element={<ArticleAgreement/>}></Route>
                <Route path={'cert-success'} element={<CertSuccess/>}></Route>
                <Route path={'newbie-guide'} element={<NewbieGuide/>}></Route>
                <Route path={'newbie-guide-detail'} element={<NewbieGuideDetail/>}></Route>
                <Route path={'policy-distribution'} element={<PolicyDistribution/>}></Route>
                <Route path={'settle-help'} element={<SettleHelp/>}></Route>
                <Route path={'register-success'} element={<RegisterSuccessPage/>}></Route>
                <Route path={'withdraw-authentication-main'} element={<WithdrawAuthenticationMain/>}></Route>
                <Route path={'withdraw-authentication-phone'} element={<WithdrawAuthenticationPhone/>}></Route>
                <Route path={'withdraw-authentication-id'} element={<WithdrawAuthenticationId/>}></Route>
                <Route path={'bind-bankcard'} element={<BindBankcard/>}></Route>
                <Route path={'callback-page'} element={<CallbackPage/>}></Route>
                <Route path={'zj'} element={<Zj></Zj>}/>
                <Route path={'zj-success'} element={<ZjSuccessPage></ZjSuccessPage>}/>
                <Route path={'zj-query'} element={<ZjQueryPage/>}/>

            </Routes>

            <Toaster/>


        </div>
    );
}

export default App;


function Root() {
    const navigate = useNavigate()

    return <List>
        <ListItem onClick={() => {
            navigate('/download-app')
        }}>下载app</ListItem>

        <ListItem onClick={() => {
            navigate('/register?code=13428934456')
        }}>注册</ListItem>

        <ListItem onClick={() => {
            navigate('/register-success')
        }}>注册成功</ListItem>

        <ListItem onClick={() => {
            navigate('/about-me')
        }}>关于我们</ListItem>

        <ListItem onClick={() => {
            navigate('/article-agreement')
        }}>协议条款</ListItem>


        <ListItem onClick={() => {
            navigate('/newbie-guide')
        }}>新手指南</ListItem>

        <ListItem onClick={() => {
            navigate('/policy-distribution')
        }}>分润政策</ListItem>

        <ListItem onClick={() => {
            navigate('/settle-help')
        }}>服务费结算说明</ListItem>

        <ListItem onClick={() => {
            navigate('/withdraw-authentication-main?uuid=3ea50a18-9c7f-4411-b944-7c809077fee7&userName=13063363359&merchno=U0000006628')
        }}>提现认证</ListItem>


    </List>
}
