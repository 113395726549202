import React from "react";
import style from './AboutUS.module.css';
import logo from '../../assets/images/about_us_logo.png'
import slogan from '../../assets/images/about_us_slogan.png'


export default function AboutUS() {
    window.document.title = '关于我们'

    return (
        <div className={style.aboutMe}>

            <div style={{height: '30vw'}}></div>

            <img className={style.picLogo} src={logo} alt={''}/>

            <img className={style.picSlogan} src={slogan} alt={''}/>

            <div style={{flex: '1'}}></div>

            <a href={'https://beian.miit.gov.cn'} style={{
                fontSize: '4vw',
                marginBottom:'4vw',
            }}>APP备案号：粤ICP备2022153500号-3A</a>

        </div>
    );
};
