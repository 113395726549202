import {makeAutoObservable, runInAction} from "mobx";
import {useState} from "react";
import {Button} from "@mui/material";
import {BaseJsonResponse, CODE_SUCCESS} from "../../api";
import toast from "react-hot-toast";
import {observer} from "mobx-react-lite";

import style from './GetCodeView.module.css';


export interface GeCodeProps {
    phone: string,

    variant: 'text' | 'contained',

    callback: (smsSeqNo: string) => void,

    method: () => Promise<BaseJsonResponse<string>>

}

class GetCodeViewModel {

    time: number = 0

    constructor() {
        makeAutoObservable(this)

    }


    /**
     * 按钮文字
     */
    get buttonText() {
        if (this.time === 0) {
            return '获取验证码'
        } else {
            return `${this.time}秒后重新获取`
        }

    }

    /**
     * 按钮是否可以点击
     */
    get buttonEnable() {
        return this.time === 0
    }


    async sendCode(phone: string,
                   method: () => Promise<BaseJsonResponse<string>>,
                   callback: (smsSeqNo: string) => void) {
        if (phone.length !== 11) {
            toast('请输入正确的手机号')
            return
        }

        this.time = 60;

        const result = await method()

        if (result.code === CODE_SUCCESS) {
            callback(result.result ?? '')
            const timer = setInterval(() => {
                runInAction(() => {
                    this.time--;
                    if (this.time === 0) {
                        clearInterval(timer)
                    }
                })

            }, 1000)
        } else {
            toast(result.message)
            runInAction(() => {
                this.time = 0
            })
        }


    }
}

function GetCodeView(props: GeCodeProps) {
    const [viewModel] = useState(() => new GetCodeViewModel())

    if (props.variant === 'text') {
        return <Button variant={'text'} onClick={() => {
            viewModel.sendCode(props.phone, props.method, props.callback).then();
        }} disabled={!viewModel.buttonEnable}> {viewModel.buttonText}</Button>
    } else {
        return <button className={style.action} onClick={() => {
            viewModel.sendCode(props.phone, props.method, props.callback).then();
        }} disabled={!viewModel.buttonEnable}> {viewModel.buttonText}</button>

    }

}

export default observer(GetCodeView)
