import {Button, Checkbox, CircularProgress, IconButton, InputAdornment, TextField} from "@mui/material";
import {NavigateFunction, useNavigate, useSearchParams} from "react-router-dom";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {useState} from "react";
import {observer} from "mobx-react-lite";
import {handleToPrivacyPolicy, handleToUserService} from "./article-agreement";
import {makeAutoObservable, runInAction} from "mobx";
import GetCodeView from "../components/get-code/GetCodeView";
import {CODE_SUCCESS, register, sendRegisterSmsCode} from "../api";
import toast from "react-hot-toast";

class RegisterViewModel {
    code: string
    phone: string
    name: string
    password: string = ''
    sms: string = ''

    //是否显示密码
    passwordVisible: boolean = false
    //复选框是否勾选了
    checked: boolean = false
    //是否处于加载中
    loading: boolean = false

    constructor(code: string) {
        this.code = code
        this.phone = localStorage.getItem('phone') ?? ''
        this.name = localStorage.getItem('name') ?? ''
        makeAutoObservable(this)
    }

    onPhoneChanged(value: string) {
        this.phone = value
        localStorage.setItem('phone', value)
    }

    onNameChanged(value: string) {
        this.name = value
        localStorage.setItem('name', value)
    }

    togglePasswordVisible() {
        this.passwordVisible = !this.passwordVisible
    }

    toggleChecked() {
        this.checked = !this.checked
    }

    async register(navigate: NavigateFunction) {
        this.loading = true;
        const result = await register({
            "userName": this.phone,
            "password": this.password,
            "merchName": this.name,
            "superMobile": this.code,
            "code": this.sms
        })

        runInAction(() => {
            this.loading = false
        })

        if (result.code === CODE_SUCCESS) {
            //注册成功，去注册成功页面
            navigate('/register-success', {
                replace: true
            })
        } else {
            toast(result.message)
        }
    }


}

function RegisterPage() {
    window.document.title = '凯蒙平台新用户注册'


    const [params] = useSearchParams()
    const code = params.get('code') ?? ''

    const navigate = useNavigate()


    const [viewModel] = useState(() => new RegisterViewModel(code))

    const inputStyle = {
        marginTop: '9vw',
        fontSize: '4vw'
    };

    return <div style={{display: "flex", flexDirection: 'column', padding: 16}}>

        <div style={{
            fontSize: 14,
            color: 'red'
        }}>仅供业内人士使用，展业需实名。
        </div>


        <TextField disabled={viewModel.loading} style={inputStyle} onChange={(event) => {
            const phone = event.target.value
            // localStorage.setItem('phone', phone)
            viewModel.onPhoneChanged(phone)
        }} placeholder={'请输入您的手机号码'} variant="standard" inputMode={'tel'} value={viewModel.phone}></TextField>


        <TextField disabled={viewModel.loading} style={inputStyle} value={viewModel.name} onChange={(event) => {
            viewModel.onNameChanged(event.target.value)
        }} placeholder={'请输入您的姓名'} variant="standard" inputMode={'text'}/>


        <TextField onChange={(event) => {
            viewModel.password = event.target.value
        }} disabled={viewModel.loading} style={inputStyle} placeholder={'请输入密码'} variant="standard" type={
            viewModel.passwordVisible ? 'text' : 'password'
        } InputProps={{
            endAdornment: (
                <InputAdornment position={'end'}>
                    {
                        <IconButton onClick={() => {
                            viewModel.togglePasswordVisible()
                        }}>
                            {
                                viewModel.passwordVisible ? <VisibilityOff/> : <Visibility/>
                            }
                        </IconButton>
                    }
                </InputAdornment>
            )
        }}/>

        <TextField onChange={(event) => {
            viewModel.sms = event.target.value
        }} disabled={viewModel.loading} style={inputStyle} placeholder={'请输入验证码'} variant="standard"
                   type={
                       'number'
                   } InputProps={{
            endAdornment: (
                <InputAdornment position={'end'}>
                    {
                        // <Button variant={'text'} onClick={() => {
                        //
                        // }}>获取验证码</Button>
                        <GetCodeView callback={() => {

                        }} phone={viewModel.phone} method={async () => {
                            return await sendRegisterSmsCode(viewModel.phone)
                        }}
                                     variant={'text'}/>
                    }
                </InputAdornment>
            )
        }}/>

        <TextField style={{

            ...inputStyle
        }} value={`推荐人展业码:` + code} disabled placeholder={'请输入您推荐人的手机号或展业码(必填)'}
                   variant="standard" inputMode={'text'}/>

        <div style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 16,
            marginBottom: 16,
            alignItems: 'center',
            fontSize: 13
        }}>
            <Checkbox onChange={() => {
                viewModel.toggleChecked()
            }} checked={viewModel.checked}/>
            我已阅读并同意
            <span style={{
                color: 'blue'
            }} onClick={handleToUserService}>《用户服务协议》</span>
            <span style={{
                color: 'blue'
            }} onClick={handleToPrivacyPolicy}>《隐私政策》</span>
        </div>

        <Button disabled={!(viewModel.checked && !viewModel.loading)} variant={'contained'} onClick={() => {
            // navigate('/register-success')

            viewModel.register(navigate).then()
            // window.location.reload()
        }}>注册</Button>

        {
            viewModel.loading && <div style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: 16
            }}>
                <CircularProgress/>
            </div>
        }

    </div>
}

export default observer(RegisterPage)

