import style from "../zj/Zj.module.css";
import app from "../../App.module.css";
import GetCodeView from "../../components/get-code/GetCodeView";
import {AppBar, Button, Checkbox, Toolbar, Typography} from "@mui/material";
import {useSearchParams} from "react-router-dom";

export default function ZjSuccessPage() {

    window.document.title = '绑定成功'


    const [params] = useSearchParams()
    const name = params.get('name') ?? ''
    const idNo = params.get('idNo') ?? ''
    const bankCardNumber = params.get('bankCardNumber') ?? ''
    const bankName = params.get('bankName') ?? ''
    const mobile = params.get('mobile') ?? ''

    return <div>
        {/*<AppBar position="static">*/}
        {/*    <Toolbar>*/}

        {/*        <Typography variant="h6" component="div" sx={{flexGrow: 1}}>*/}
        {/*            恭喜您，绑定成功*/}
        {/*        </Typography>*/}

        {/*    </Toolbar>*/}
        {/*</AppBar>*/}

        <div className={style.root}>
            <div className={style.content}>


                <div className={style.row} style={{
                    marginTop: '10vw'
                }}>
                    <div className={style.title}>账户姓名</div>
                    <input className={style.input}
                           readOnly={true}
                           value={name}
                           style={{flexGrow: 1}}></input>
                </div>
                <div className={style.divider} style={{
                    marginTop: '5vw'
                }}/>
                <div className={style.row} style={{
                    marginTop: '5vw'
                }}>
                    <div className={style.title}>身份证号码</div>
                    <input className={style.input}
                           readOnly={true}
                           value={idNo}
                           style={{flexGrow: 1}}></input>
                </div>

                <div className={style.divider} style={{
                    marginTop: '5vw'
                }}/>

                <div className={style.row} style={{
                    marginTop: '5vw'
                }}>
                    <div className={style.title}>银行卡号</div>
                    <input className={style.input}
                           readOnly={true}
                           value={bankCardNumber}
                           style={{flexGrow: 1}}></input>
                </div>

                <div className={style.divider} style={{
                    marginTop: '5vw'
                }}/>

                <div className={style.row} style={{
                    marginTop: '5vw'
                }}>
                    <div className={style.title}>开户银行</div>
                    <input className={style.input}
                           readOnly={true}
                           value={bankName}
                           style={{flexGrow: 1}}></input>
                </div>

                <div className={style.divider} style={{
                    marginTop: '5vw'
                }}/>

                <div className={style.row} style={{
                    marginTop: '5vw'
                }}>
                    <div className={style.title}>手机号</div>
                    <input className={style.input}
                           readOnly={true}
                           value={mobile}
                           style={{flexGrow: 1}}></input>
                </div>
            </div>

            <div style={{
                marginTop: '10vw',
                padding: '4vw',
                backgroundColor: '#1ED193',
                color: 'white',
                textAlign: 'center',
                fontSize: '6vw'
            }}>恭喜您，绑卡成功
            </div>
        </div>


    </div>
}
