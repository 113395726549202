export function isWeChatBrowser() {
    return /MicroMessenger/i.test(navigator.userAgent);
}

const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
const isAndroid = /Android/i.test(navigator.userAgent);


export {
    isIOS,
    isAndroid
};
