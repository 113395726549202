import axios from "axios";

const instance = axios.create({
    baseURL: 'https://www.kaimengkeji.com/sfw-api/',
    // baseURL: 'http://183.2.157.223:1234/sfw-api/',
    // baseURL: 'http://localhost:8080/',
    withCredentials: true,
    headers: {
        "Content-Type": 'application/json',
    },
    transformRequest: [
        function (params) {
            return JSON.stringify(params)
        }
    ]
});

const defaultError = {
    code: '500',
    message: '网络错误',
    result: null
}

export const CODE_SUCCESS = '00000'

/**
 * 绑定银行卡获取验证码
 */
export async function applyBindBankCard(
    request: {
        "userid": string,
        "bankcard": string,
        "tel": string
    },
    header: LoginUserHeader
) {
    const path = 'chengXinTong/applyBindBankCard'

    try {
        const response =
            await instance.post<BaseJsonResponse<string>>(path, request, {
                headers: {
                    ...header
                }
            })

        return response.data
    } catch (e) {
        return {
            code: '500',
            message: '网络错误',
            result: null
        }
    }
}


/**
 * 绑定银行卡
 */
export async function bindBankCard(
    request: {
        "userid": string,
        "bankcard": string,
        "tel": string,
        "signSeqNo": string,
        "smsCode": string,
        "accountName": string,
        "bankName": string
    },
    header: LoginUserHeader
) {
    const path = 'chengXinTong/bindBankCard'

    try {
        const response =
            await instance.post<BaseJsonResponse<string>>(path, request, {
                headers: {
                    ...header
                }
            })

        return response.data
    } catch (e) {
        return defaultError
    }
}

/**
 * 中转页面调用后台接口
 */
export async function cxtCallback(request: {
    type: string,
    merchno: string
}) {
    const path = 'chengXinTong/callbackPage'


    try {
        const response =
            await instance.post<BaseJsonResponse<string>>(path, {}, {
                params: request
            })

        return response.data
    } catch (e) {
        return {
            code: '500',
            message: '网络错误',
            result: null
        }
    }
}

/**
 * 获取真实姓名
 */
export async function getRealName(header: LoginUserHeader): Promise<BaseJsonResponse<{ fullName: string }>> {
    const path = 'merchInfo/getImgStatus'
    try {
        const response =
            await instance.get<BaseJsonResponse<{ fullName: string }>>(path, {
                headers: {
                    ...header
                }
            })

        return response.data
    } catch (e) {
        return defaultError
    }
}

/**
 * 获取注册验证码
 * @param phone 手机号
 */
export async function sendRegisterSmsCode(phone: string): Promise<BaseJsonResponse<string>> {
    try {
        const response =
            await instance.post<BaseJsonResponse<string>>('common/sendMsg', {
                'phone': phone,
                'msgType': '4001'
            })

        return response.data
    } catch (e) {
        return {
            code: '500',
            message: '网络错误',
            result: null
        }
    }
}


export async function sendCxtSmsCode(phone: string, header: LoginUserHeader | null): Promise<BaseJsonResponse<string>> {
    try {
        const response =
            await instance.post<BaseJsonResponse<string>>('chengXinTong/getSmsCode', {
                'phone': phone,
            }, {
                headers: {
                    ...header
                }
            })

        return response.data
    } catch (e) {
        return {
            code: '500',
            message: '网络错误',
            result: null
        }
    }
}

/**
 * 注册
 */
export async function cxtRegister(phone: string, code: string, smsSeqNo: string, header: LoginUserHeader): Promise<BaseJsonResponse<string>> {
    try {
        const response =
            await instance.post<BaseJsonResponse<string>>('chengXinTong/userReg', {
                'phone': phone,
                'code': code,
                'smsSeqNo': smsSeqNo,
            }, {
                headers: {
                    ...header
                }
            })

        return response.data
    } catch (e) {
        return {
            code: '500',
            message: '网络错误',
            result: null
        }
    }
}

/**
 * 上传身份证人像面图片
 */
export async function uploadIdImageFront(file: File, header: LoginUserHeader): Promise<BaseJsonResponse<IdCardFrontResponse>> {
    try {
        const formData = new FormData()
        formData.append('file', file)
        formData.append('type', '1')
        const response =
            await instance.post<BaseJsonResponse<IdCardFrontResponse>>('chengXinTong/uploadImage', formData, {
                headers: {
                    ...header,
                    'Content-Type': 'multipart/form-data'
                }, transformRequest: []
            })

        return response.data
    } catch (e) {
        return {
            code: '500',
            message: '网络错误',
            result: null
        }
    }
}


/**
 * 保存身份证信息
 */
export async function saveIdCard(
    request: {
        "userid": string,
        "name": string,
        "idcard": string,
        "address": string,
        "picname1": string,
        "picname2": string
    }, header: LoginUserHeader
): Promise<BaseJsonResponse<string>> {
    try {
        const response =
            await instance.post<BaseJsonResponse<string>>('chengXinTong/saveIdCard', request, {
                headers: {
                    ...header
                }
            })

        return response.data
    } catch (e) {
        return {
            code: '500',
            message: '网络错误',
            result: null
        }
    }
}

/**
 * 上传身份证国徽面图片
 */
export async function uploadIdImageBack(file: File, header: LoginUserHeader): Promise<BaseJsonResponse<IdCardBackResponse>> {
    try {
        const formData = new FormData()
        formData.append('file', file)
        formData.append('type', '2')
        const response =
            await instance.post<BaseJsonResponse<IdCardBackResponse>>('chengXinTong/uploadImage', formData, {
                headers: {
                    ...header,
                    'Content-Type': 'multipart/form-data'
                }, transformRequest: []
            })

        return response.data
    } catch (e) {
        return {
            code: '500',
            message: '网络错误',
            result: null
        }
    }
}

export async function cxtGetAgreement(userId: string, header: LoginUserHeader): Promise<BaseJsonResponse<string>> {
    try {
        const response =
            await instance.post<BaseJsonResponse<string>>('chengXinTong/getAgreement', {
                'userid': userId
            }, {
                headers: {
                    ...header
                }
            })

        return response.data
    } catch (e) {
        return {
            code: '500',
            message: '网络错误',
            result: null
        }
    }
}


export async function zjIndex(id: string): Promise<BaseJsonResponse<ZjResponse>> {
    try {
        const response =
            await instance.get<BaseJsonResponse<ZjResponse>>('zj/' + id,)
        return response.data
    } catch (e) {
        return {
            code: '500',
            message: '网络错误',
            result: null
        }
    }

}


/**
 * 用户注册
 * @param request 注册请求
 */
export async function register(request: RegisterRequest): Promise<BaseJsonResponse<string>> {
    try {
        const response =
            await instance.get<BaseJsonResponse<string>>('register/insert', {
                params: request
            })
        return response.data
    } catch (e) {
        return {
            code: '500',
            message: '网络错误',
            result: null
        }
    }
}

export async function signAcctAgreement(userid: string, header: LoginUserHeader) {
    try {
        const response =
            await instance.post<BaseJsonResponse<string>>('chengXinTong/signAcctAgreement', {
                userid
            }, {
                headers: {
                    ...header
                }
            })
        return response.data
    } catch (e) {
        return {
            code: '500',
            message: '网络错误',
            result: null
        }
    }
}

export async function getStepInfo(
    header: LoginUserHeader
) {
    try {
        const response =
            await instance.post<BaseJsonResponse<Array<XctStepInfo>>>('chengXinTong/getXctStepInfo', {}, {
                headers: {
                    ...header
                }
            })
        return response.data
    } catch (e) {
        return {
            code: '500',
            message: '网络错误',
            result: null
        }
    }
}

export async function zjBindCard(request: ZjBindCardRequest, customerNo: string): Promise<BaseJsonResponse<ZjBindCardResponse>> {
    try {
        const response =
            await instance.post<BaseJsonResponse<ZjBindCardResponse>>('zj/userCardBinding', request, {
                headers: {
                    "customerNo": customerNo
                }
            })
        return response.data
    } catch (e) {
        return {
            code: '500',
            message: '网络错误',
            result: null
        }
    }
}


export async function zjQuery(mobile: string, name: string): Promise<BaseJsonResponse<any>> {
    try {
        const response =
            await instance.post<BaseJsonResponse<any>>('zj/selectInfo', {
                "bankAccountName": name,
                "bankPhoneNumber": mobile
            })
        return response.data
    } catch (e) {
        return {
            code: '500',
            message: '网络错误',
            result: null
        }
    }
}

export async function zjConfirmBindCard(txSN: string,
                                        sMSCode: string): Promise<BaseJsonResponse<any>> {
    try {
        const response =
            await instance.post<BaseJsonResponse<any>>('zj/bindingOk', {
                txSN, sMSCode
            })
        return response.data
    } catch (e) {
        return {
            code: '500',
            message: '网络错误',
            result: null
        }
    }
}

/**
 * 中金银行卡列表
 */
export async function zjBankList(): Promise<BaseJsonResponse<Array<BankPairResponse>>> {
    try {
        const response =
            await instance.get('bankCode/list')
        return response.data
    } catch (e) {
        return {
            code: '500',
            message: '网络错误',
            result: null
        }
    }
}

export interface ZjBindCardRequest {
    "credentialNumber": string,
    "bankID": string,
    "bankAccountName": string,
    "bankAccountNumber": string,
    "bankPhoneNumber": string,
    "customerName": string,
    bankName: string
}

export interface ZjBindCardResponse {
    "txSN": string,
    "userId": string
}

export interface RegisterRequest {
    userName: string,
    password: string,
    merchName: string,
    superMobile: string,
    code: string

}

export interface BaseJsonResponse<T> {
    code: string,
    message: string,
    result: T | null
}

export interface LoginUserHeader {
    uuid: string,
    userName: string,
    merchno: string,
}

export interface IdCardFrontResponse {
    "address": string,
    "idcard": string,
    "name": string,
    "picname1": string,
    "trans_no": ""
}

export interface IdCardBackResponse {
    "picname2": string,
    "trans_no": string
}

export interface XctStepInfo {
    content: string,
}

export interface ZjResponse {
    "createTime": string,
    "customerNo": string,
    "id": number,
    "idNo": string,
    "mobile": string,
    "name": string
}

export interface BankPairResponse {
    bankCode: string,
    bankName: string
}
