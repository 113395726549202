import {makeAutoObservable} from "mobx";
import {useEffect, useState} from "react";

import style from './CallbackPage.module.css'
import {Button, CircularProgress} from "@mui/material";
import {observer} from "mobx-react-lite";
import {NavigateFunction, useNavigate, useSearchParams} from "react-router-dom";
import {CODE_SUCCESS, cxtCallback} from "../../api";
import toast from "react-hot-toast";

enum State {
    loading, error
}

class CallbackViewModel {

    state: State = State.loading
    navigate: NavigateFunction

    constructor(navigate: NavigateFunction) {
        this.navigate = navigate
        makeAutoObservable(this)

    }

    refresh(type: string, merchno: string) {
        // this.navigate(-1)
        this.state = State.loading
        cxtCallback({
            type: type,
            merchno: merchno
        }).then(result => {
            if (result.code === CODE_SUCCESS) {
                const size = window.history.length
                console.log('window.history.length = ' + size)
                this.navigate(-(size - 1))
                // window.location.reload()
            } else {
                this.state = State.error
                toast(result.message)
            }
        })
    }
}


function CallbackPage() {
    const navigate = useNavigate()

    const [params] = useSearchParams()
    const type = params.get('type') ?? ''
    const merchno = params.get('merchno') ?? ''

    const [viewModel] = useState(() => new CallbackViewModel(navigate))


    useEffect(() => {
        viewModel.refresh(type, merchno)
    }, [merchno, type, viewModel])


    return <div className={style.root}>

        {
            viewModel.state === State.loading ? <CircularProgress/> :
                <Button variant={'outlined'} onClick={() => {
                    viewModel.refresh(type, merchno)
                }}>出错了，点我重试</Button>
        }

    </div>
}

export default observer(CallbackPage)
