import style from './RegisterSuccess.module.css'
import image from '../../assets/images/register_success.png';
import {Button} from "@mui/material";
import {useNavigate} from "react-router-dom";

export default function RegisterSuccessPage() {
    window.document.title = '注册成功'

    const navigate = useNavigate()

    return <div className={style.root}>


        <img src={image} className={style.image} alt={''}/>

        <div style={{
            fontSize: 20,
            marginTop: 28
        }}>恭喜您!
        </div>
        <div style={{
            color: '#06C177',
            fontSize: 24,
            fontWeight: 'bold',
            marginBottom: 32
        }}>注册成功！
        </div>


        <Button variant={'contained'} onClick={() => {
            navigate('/download-app')
        }} className={style.button}>下载app</Button>

    </div>
}
