import {observer} from "mobx-react-lite";
import style from './ZjQuery.module.css'
import {useState} from "react";
import {Button, Input} from "@mui/material";
import {CODE_SUCCESS, zjQuery} from "../../api";
import toast from "react-hot-toast";
import {makeAutoObservable, runInAction} from "mobx";
import {NavigateFunction, useNavigate} from "react-router-dom";

class ViewModel {

    loading: boolean = false

    mobile: string = ''

    name: string = ''

    // errorMessage: string = ''

    constructor() {
        makeAutoObservable(this)
    }


    dialogProps: DialogProps | null = null

    query(navigate: NavigateFunction) {

        if (this.mobile.length === 0 || this.name.length === 0) {
            toast('姓名和手机号必须输入')
            return
        }
        this.loading = true
        // this.errorMessage = ''
        zjQuery(this.mobile, this.name).then(result => {

            if (result.code === CODE_SUCCESS) {
                runInAction(() => {
                    // this.errorMessage = result.message
                    this.loading = false
                })
                navigate('/zj?id=' + result.result['id'], {
                    replace: true
                })
            } else {
                // toast(result.message)
                runInAction(() => {
                    // this.errorMessage = result.message
                    this.loading = false

                    this.dialogProps = {
                        title: '系统提示',
                        content: result.message,
                        buttonText: '好的',
                        onConfirm: () => {

                            runInAction(() => {
                                this.dialogProps = null
                            })
                        }
                    }
                })


            }
        })
    }
}

interface DialogProps {
    title: string,
    content: string,
    buttonText: string
    onConfirm: () => void,
}

function Dialog(props: DialogProps) {
    return <div className={style.dialogContainer}>


        <div className={style.dialogTitle}>{props.title}</div>

        <div className={style.dialogContent}>{props.content}</div>

        <div className={style.dialogButton}>
            <Button style={{width: '100%'}} onClick={props.onConfirm} variant={'contained'}>{

                props.content === '系统无法查询到您的信息，无法认证，请联系经办人。' ? '重试' : '好的'
            }</Button>

        </div>

    </div>
}


function ZjQueryPage() {
    window.document.title = '小蒙车服'

    const [viewModel] = useState(() => new ViewModel())
    const navigate = useNavigate()


    return <div className={style.div}>

        <div className={style.root}>


            <div className={style.header}>
                您好，<br/>欢迎来到小蒙车服平台
            </div>

            <Input className={style.input} style={{
                marginTop: '8vw',
                maxLines: 1
            }} placeholder={'请输入中国大陆手机号'} inputMode={'tel'} onChange={(event) => {
                viewModel.mobile = event.target.value
            }} disabled={viewModel.loading}/>

            <Input className={style.input} style={{
                marginTop: '8vw',
                maxLines: 1
            }} placeholder={'请输入姓名'} inputMode={'text'} onChange={(event) => {
                viewModel.name = event.target.value
            }} disabled={viewModel.loading}/>

            <Button style={{
                width: '92vw',
                marginTop: '8vw',
            }} variant={'contained'}
                    disabled={viewModel.loading}
                    onClick={() => {
                        viewModel.query(navigate)
                    }}
            >{'认证'}</Button>

            <div className={style.hint}>温馨提示:<br/>请填写办理业务时的姓名和手机号码。</div>


        </div>


        {
            viewModel.dialogProps !== null && <div className={style.dialog}>
                <Dialog title={viewModel.dialogProps.title} content={viewModel.dialogProps.content}
                        buttonText={viewModel.dialogProps.buttonText} onConfirm={() => {
                    viewModel.dialogProps!.onConfirm()
                }
                }/>
            </div>
        }

    </div>
}


export default observer(ZjQueryPage)
