import React from "react";
import style from "./CertSuccess.module.css";
import des from '../../assets/images/cert_des.png'
import banner from '../../assets/images/cert_success.png'

const CertSuccess = () => {

    window.document.title = '认证成功'
    return (
        <div className={style.certSuccess}>
            <img className={style.banner} alt={''} src={banner}/>
            <div className={style.title}>个人信息认证成功！</div>
            <img className={style.des} alt={''} src={des}/>
            <div className={style.btn} onClick={() => {
                // @ts-ignore
                window.flutter_inappwebview.callHandler('onWebViewClose');
            }}>
                开始展业
            </div>
        </div>
    );
};
export default CertSuccess;
