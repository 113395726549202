import React from "react";
import style from  "./ArticleAgreement.module.css";

import arrow from '../../assets/images/arraw.png'

export const handleToUserService = () => {
    window.location.href = "https://actstatic.insnail.com/test/yonhuxieyi.html?" + new Date().getTime();
};
export const handleToPrivacyPolicy = () => {
    window.location.href = "https://actstatic.insnail.com/test/yonghuyinsizhengce.html?" + new Date().getTime();
};
const ArticleAgreement = () => {

    window.document.title = '协议条款'

    return (
        <div className={style.articleAgreement}>
            <div className={style.item} onClick={handleToUserService}>
                <span className={style.itemText}>《用户服务协议》</span>
                <img
                    src={arrow}
                    alt={''}
                    className={style.image}
                />
            </div>
            <div className={style.item} onClick={handleToPrivacyPolicy}>
                <span className={style.itemText}>《隐私政策》</span>
                <img
                    src={arrow}
                    alt={''}
                    className={style.image}
                />
            </div>
            {/* <div className={styles.item}>

        <span>《协议签署》</span>
        <img
          className="icon_1-0"
          src={
            "https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngc2be739eee8d8f017ed10525648722d7660a6fb009b50d78fdae6b9bafb6000b"
          }
        />

      </div> */}
        </div>
    );
};
export default ArticleAgreement;
