import React from "react";
import {useSearchParams} from "react-router-dom";

const NewbieGuideDetail = () => {
    const [params] = useSearchParams()
    const index = params.get('index')
    return (
        <img alt={''} width="100%" src={require(`../../assets/images/newbie-guide-detail/${index}.png`)}/>
    );
};
export default NewbieGuideDetail;
