import {observer} from "mobx-react-lite";
import style from './WithdrawAuthenticationId.module.css'
import front from '../../assets/images/id_placeholder_front.png'
import back from '../../assets/images/id_placeholder_back.png'
import {Button, CircularProgress} from "@mui/material";
import {makeAutoObservable, runInAction} from "mobx";
import {ChangeEvent, useState} from "react";
import {NavigateFunction, useNavigate, useSearchParams} from "react-router-dom";
import {CODE_SUCCESS, LoginUserHeader, saveIdCard, uploadIdImageBack, uploadIdImageFront} from "../../api";
import toast from "react-hot-toast";


class WithdrawAuthenticationIdViewModel {
    number: string = ''
    name: string = ''
    address: string = ''

    front: string = front
    back: string = back

    frontImagePath: string = ''
    backImagePath: string = ''

    userHeader: LoginUserHeader

    loading: boolean = false


    uploading: boolean = false

    constructor(userHeader: LoginUserHeader) {
        this.userHeader = userHeader
        makeAutoObservable(this)
    }

    buttonEnable(): boolean {
        return this.frontImagePath.length > 0 && this.backImagePath.length > 0 && !this.loading
    }

    async onImageChanged(file: File, isFront: boolean) {
        const url = URL.createObjectURL(file)
        this.uploading = true
        if (isFront) {
            this.front = url
            const result = await uploadIdImageFront(file, this.userHeader)
            if (result.code === CODE_SUCCESS) {
                runInAction(() => {
                    this.number = result.result!.idcard
                    this.name = result.result!.name
                    this.address = result.result!.address
                    this.frontImagePath = result.result!.picname1
                })
            } else {
                toast(result.message)
                runInAction(() => {
                    this.front = front
                    this.name = ''
                    this.address = ''
                    this.number = ''
                    this.frontImagePath = ''
                })
            }
        } else {
            this.back = url
            const result = await uploadIdImageBack(file, this.userHeader)
            if (result.code === CODE_SUCCESS) {
                runInAction(() => {
                    this.backImagePath = result.result!.picname2
                })
            } else {
                toast(result.message)
                runInAction(() => {
                    this.back = back
                    this.backImagePath = ''
                })
            }
        }

        runInAction(() => {
            this.uploading = false
        })


    }

    async commit(userId: string, navigate: NavigateFunction) {
        this.loading = true

        saveIdCard({
            userid: userId,
            idcard: this.number,
            name: this.name,
            address: this.address,
            picname1: this.frontImagePath,
            picname2: this.backImagePath
        }, this.userHeader).then(value => {
            runInAction(() => {
                this.loading = false
            })
            if (value.code === CODE_SUCCESS) {
                navigate(-1)
            } else {
                toast(value.message)
            }
        })

    }
}

function WithdrawAuthenticationId() {
    window.document.title = '资料补充'

    const [params] = useSearchParams()
    const uuid = params.get('uuid') ?? ''
    const userName = params.get('userName') ?? ''
    const merchno = params.get('merchno') ?? ''
    const userId = params.get('userId') ?? ''

    const navigate = useNavigate()

    const userHeader = {
        uuid: uuid,
        userName: userName,
        merchno: merchno
    }

    const [viewModel] = useState(() => new WithdrawAuthenticationIdViewModel(userHeader))

    const handleInputChanged = (e: ChangeEvent<HTMLInputElement>, front: boolean) => {
        const file = e.target.files?.[0]
        if (file) {
            //展示刚才选择的图片
            viewModel.onImageChanged(file, front).then()
        }
    }

    return (
        <div className={style.root}>
            <div className={style.header}></div>
            <div className={style.content}>
                <div className={style.idImageContainer}>

                    <div className={style.idImageBox}>
                        <img src={viewModel.front} className={style.idImage} alt={''}/>
                        <input type={'file'} onChange={(input) => {
                            handleInputChanged(input, true)

                        }} className={style.fileInput} disabled={viewModel.loading || viewModel.uploading}
                               accept="image/jpg, image/png"/>
                    </div>
                    <div className={style.idImageBox}>
                        <img src={viewModel.back} className={style.idImage} alt={''}/>
                        <input disabled={viewModel.loading || viewModel.uploading}
                               onChange={(event) => handleInputChanged(event, false)} type={'file'}
                               className={style.fileInput} accept="image/jpg, image/png"/>
                    </div>
                </div>

                <div className={style.titleName}>身份证姓名</div>

                <div className={style.input}>{viewModel.name}</div>

                <div className={style.divider}/>

                <div className={style.titleNumber}>身份证号码</div>

                <div className={style.input}>{viewModel.number}</div>

                <div className={style.divider}/>

                <div className={style.titleAddress}>身份证地址</div>

                <div className={style.input}>{viewModel.address}</div>

                {
                    <CircularProgress/> && viewModel.uploading
                }

            </div>

            {
                viewModel.uploading && <div className={style.uploadingContainer}>
                    <div>资料上传中，请稍后...</div>
                    <CircularProgress/>
                </div>
            }
            <div style={{flexGrow: 1}}/>
            <Button variant={'contained'} style={{
                margin: '4vw'
            }} className={style.button} disabled={!viewModel.buttonEnable()} onClick={() => {
                viewModel.commit(userId, navigate).then()
            }}>提交</Button>
        </div>
    )
}

export default observer(WithdrawAuthenticationId)
