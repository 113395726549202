import React from "react";
import style from "./SettleHelp.module.css";

const SettleHelp = () => {
    return (
        <div className={style.settleHelp}>
            <div className={style.content}>
                <div className={style.headerTitle}>—— 服务费结算说明 ——</div>
                <div className={style.title}>【服务费结算时间】</div>
                <div className={style.textWord}>
                    对私结算：每日T+1结算，广州市凯蒙科技有限公司将通过第三方税筹平台结算。
                    <div>
                        对公结算：每日T+1结算，广州市凯蒙科技有限公司将把服务费直接打入凯蒙云服APP绑定的对公账户。
                    </div>
                    <div>注明：</div>
                    <div>
                        结算前提是用户已在凯蒙云服APP实名认证通过、已绑定结算银行卡、且已签署平台相关
                        协议，结算时间遇节假日顺延。
                    </div>
                    <div>
                        凯蒙云服APP用户若为注册公司，且公司法人为本人，须转对公账户结算，需要提供的材
                        料为：身份证、营业执照、开户许可证或账户结算信息表。
                    </div>
                    <div>凯蒙云服APP用户须阅读且签署平台相关协议。</div>
                </div>
            </div>
            <div className={style.content}>
                <div className={style.title}>【发票相关信息】</div>
                <div className={style.textWord}>
                    （1）名称：广州市凯蒙科技有限公司
                    <div>（2）税号：91440101MA9UWJJ595 </div>
                    <div>
                        （3）地 址、电 话：广州市南沙区丰泽东路106号（自编1号楼）X1301-G022220（集群注册）（JM）
                    </div>
                    <div>（4）开户行及账号：中国银行股份有限公司广州黄阁支行680873838981</div>
                </div>
            </div>

            <div className={style.content}>
                <div className={style.title}>【凯蒙云服APP开票内容】</div>
                <div className={style.textWord}>
                    <div>（1）服务费</div>
                    <div>（2）技术服务费</div>
                    <div>说明：</div>
                    <div>
                        1、以上两种开票项目可添加的前缀：信息系统、现代、其他现代、专业、信息，例：现代*服务费；
                    </div>
                    <div>
                        2、开票内容中，切忌含有“咨询”、“开发”、“研发”、“经纪”、“佣金”、“劳务费”、“软件”、“金融”、“POS”、“POS代理”、“POS机”等字样。
                    </div>
                </div>
            </div>

            <div className={style.content}>
                <div className={style.title}>【发票类型】</div>
                <div className={style.textWord}>
                    <div>（1）可接受电子发票（无须邮寄）；</div>
                    <div>（2）须开具增值税专用发票，税率6%，用户可根据自身公司税收情况开具，若有税率差额则用扣取的手续费抵扣完再返还。</div>
                    <div>
                        所有开票金额必须与提现金额一致
                    </div>
                    <div>
                        若结算主体为“个人用户”，公司将在用户提现申请时扣取6%+3元/笔的手续费再作结算；
                    </div>
                    <div>
                        若结算主体为“机构用户”，公司将在用户提现申请时扣取6%+3元/笔的手续费再作结算，届时用户开票后，公司将手续费差额返还用户APP钱包。
                    </div>
                </div>
            </div>

            <div className={style.content}>
                <div className={style.title}>【纸质发票邮寄地址】</div>
                <div className={style.textWord}>
                    <div>每月3日前，对公结算的公司需要按服务费等额开具发票，并将发票邮寄到如下地址</div>
                    <div>收件人：财务部APP结算组</div>
                    <div>电话：020-39037429</div>
                    <div>收件地址：广州市天河区华强路1号珠控国际中心1501室</div>
                </div>
            </div>
            <div className={style.content}>
                <div className={style.title}>【电子发票发送邮箱】</div>
                <div className={style.textWord}>邮箱：infor@kaimengkeji.cn</div>
            </div>
        </div>
    );
};
export default SettleHelp;
