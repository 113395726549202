import React from "react";
import style from "./PolicyDistribution.module.css";

const PolicyDistribution = () => {
    window.document.title = '分润政策'
    return (
        <div>
            <img className={style.img} alt={''} src={require(`../../assets/images/policy-distribution/1.jpg`)}/>
            <img className={style.img} alt={''} src={require(`../../assets/images/policy-distribution/2.jpg`)}/>
            <img className={style.img} alt={''} src={require(`../../assets/images/policy-distribution/3.jpg`)}/>

        </div>
    );
};
export default PolicyDistribution;
