import {observer} from "mobx-react-lite";
import {makeAutoObservable, runInAction} from "mobx";
import React, {useState} from "react";
import style from "./WithdrawAuthenticationMain.module.css";

import imageCurrent from '../../assets/images/step_current.png'

import imageDone from '../../assets/images/step_done.png'


import logo from '../../assets/images/withdraw_authentication_main_logo.png'
import {CODE_SUCCESS, cxtGetAgreement, getStepInfo, LoginUserHeader, signAcctAgreement} from "../../api";
import {useNavigate, useSearchParams} from "react-router-dom";
import {Button, CircularProgress} from "@mui/material";
import toast from "react-hot-toast";

interface StepItemProps {
    index: number,
    text: string,
    status: StepStatus,
    actionText: string,
    onClick: () => void
}


const stepArray = [
    {
        index: 1,
        text: '短信认证',
        actionText: '去验证'
    },
    {
        index: 2,
        text: '资料补充',
        actionText: '去填写'
    },
    {
        index: 3,
        text: '《开户协议》',
        actionText: '去确认'
    },
    {
        index: 4,
        text: '《任务协议》',
        actionText: '去签署'
    },
    {
        index: 5,
        text: '绑定银行卡',
        actionText: '去绑卡'
    }
]

enum StepStatus {
    done, current, notYet
}

class WithdrawAuthenticationMainViewModel {

    ///当前处于第几步
    currentStep: number = 0

    ///是否出错
    error: boolean = false

    header: LoginUserHeader

    loading: boolean = false

    //完成第一步在步骤信息接口里面会有userId
    userId: string = ''

    // step3Url: string = ''

    constructor(header: LoginUserHeader) {
        makeAutoObservable(this)

        this.header = header
        this.reload();
    }

    reload() {
        this.currentStep = 0
        this.error = false
        getStepInfo(this.header).then((result) => {
            // console.log(result)
            runInAction(() => {
                if (result.result) {
                    this.error = false
                    this.currentStep = result.result.length + 1

                    if (result.result.length > 0) {
                        this.userId = result!.result[0]!.content
                    }

                    // if (result.result.length > 1) {
                    //     this.step3Url = result.result[1].content
                    // }
                } else {
                    this.error = true
                }
            })
        })
    }

    async step3() {
        // if (this.step3Url.length !== 0) {
        //     window.location.href = this.step3Url
        //     return
        // }
        this.loading = true
        const result = await signAcctAgreement(this.userId, this.header)
        runInAction(() => {
            this.loading = false
        })

        if (result.code === CODE_SUCCESS) {
            const url = result.result

            if (url === null) {
                this.reload()
            } else {
                window.location.href = url
            }
        } else {
            toast(result.message)
        }
    }

    async step4() {
        this.loading = true
        // setInterval(() => {
        //     runInAction(() => {
        //         this.loading = false
        //     })
        // }, 2000)
        const result = await cxtGetAgreement(this.userId, this.header)
        runInAction(() => {
            this.loading = false
        })

        if (result.code === CODE_SUCCESS) {
            window.location.href = result.result ?? ''
        } else {
            toast(result.message)
        }
    }

}

function WithdrawAuthenticationMain() {
    window.document.title = '提现认证'

    const [params] = useSearchParams()
    const uuid = params.get('uuid') ?? ''
    const userName = params.get('userName') ?? ''
    const merchno = params.get('merchno') ?? ''
    // const userId = params.get('userId') ?? ''

    const userHeader = {
        uuid: uuid,
        userName: userName,
        merchno: merchno
    }

    const navigate = useNavigate()


    const [viewModel] = useState(() => new WithdrawAuthenticationMainViewModel(userHeader))

    if (viewModel.error) {

        return <div className={style.centerContainer}>
            <Button variant={'outlined'} onClick={viewModel.reload}>出错了，点我重试</Button>
        </div>
    }

    if (viewModel.currentStep === 0) {
        return <div className={style.centerContainer}>
            <CircularProgress/>
        </div>
    }


    return <div className={style.root}>


        <img src={logo} className={style.logo} alt={''}/>

        <div className={style.title}>请按顺序完成以下步骤</div>
        <div className={style.list}>

            {stepArray.map((item, index) => {
                let status: StepStatus
                if (index < viewModel.currentStep - 1) {
                    status = StepStatus.done
                } else if (index === viewModel.currentStep - 1) {
                    status = StepStatus.current
                } else {
                    status = StepStatus.notYet
                }

                return <StepItem key={index} index={item.index} text={item.text} status={status}
                                 actionText={item.actionText} onClick={() => {

                    if (viewModel.loading) {
                        return
                    }
                    switch (index) {
                        case 0:
                            // window.location.href = `withdraw-authentication-phone?uuid=${uuid}&userName=${userName}&merchno=${merchno}`
                            navigate('/withdraw-authentication-phone?uuid=' + uuid + '&userName=' + userName + '&merchno=' + merchno)
                            break;
                        case 1:
                            // window.location.href = `withdraw-authentication-id?uuid=${uuid}&userName=${userName}&merchno=${merchno}`
                            navigate('/withdraw-authentication-id?uuid=' + uuid + '&userName=' + userName + '&merchno=' + merchno + '&userId=' + viewModel.userId)

                            break;
                        case 2:
                            viewModel.step3().then()
                            break;
                        case 3:
                            // window.location.href = `withdraw-authentication-task-agreement?uuid=${uuid}&userName=${userName}&merchno=${merchno}`
                            viewModel.step4().then()
                            break;
                        case 4:
                            // window.location.href = `bind-bankcard?uuid=${uuid}&userName=${userName}&merchno=${merchno}`
                            navigate('/bind-bankcard?uuid=' + uuid + '&userName=' + userName + '&merchno=' + merchno + '&userId=' + viewModel.userId)

                            break;
                    }
                }
                }/>
            })
            }

        </div>

        {
            viewModel.loading && <CircularProgress/>
        }


    </div>
}


function StepItem(props: StepItemProps) {


    return <div
        className={props.status === StepStatus.done ? style.stepItemDone : props.status === StepStatus.current ? style.stepItemCurrent : style.stepItemNotYet}
        onClick={() => {
            if (props.status === StepStatus.current) {
                props.onClick()
            }
        }}>
        <div>步骤{props.index}：</div>
        <div style={{
            flex: 1
        }}>{props.text}</div>

        <div
            className={props.status === StepStatus.done ? style.actionTextDone : props.status === StepStatus.current ? style.actionTextCurrent : style.actionTextNotYet}

        >
            {props.status === StepStatus.done ? '已完成' : props.actionText}
        </div>

        <img className={style.image} src={props.status === StepStatus.done ? imageDone : imageCurrent} alt={''}/>

    </div>
}

export default observer(WithdrawAuthenticationMain)
