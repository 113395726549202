import {observer} from "mobx-react-lite";
import {NavigateFunction, useNavigate, useSearchParams} from "react-router-dom";
import {makeAutoObservable, runInAction} from "mobx";
import {useState} from "react";
import {
    BankPairResponse,
    BaseJsonResponse,
    zjBankList,
    zjBindCard,
    zjConfirmBindCard,
    zjIndex,
    ZjResponse
} from "../../api";
import style from "../zj/Zj.module.css";
import {
    AppBar,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Toolbar
} from "@mui/material";
import app from "../../App.module.css";
import GetCodeView from "../../components/get-code/GetCodeView";
import {List} from "antd-mobile";
import {Close, Search} from "@mui/icons-material";
import toast from "react-hot-toast";

class ViewModel {

    zjResponse: BaseJsonResponse<ZjResponse> | undefined = undefined

    ///银行卡号码
    bankCard: string = ''
    // bankName: string = ''
    mobile: string = ''
    code: string = ''
    //复选框是否勾选了
    checked: boolean = false
    loading: boolean = false

    ///选中的银行
    selectedBand: BankPairResponse | null = null

    bankList: Array<BankPairResponse> = []

    private txSN: string = ''

    updateSelectedBand(value: BankPairResponse) {
        this.selectedBand = value
    }

    updateMobile(value: string) {
        this.mobile = value
    }

    updateCode(value: string) {
        this.code = value
    }

    commit(navigate: NavigateFunction) {
        if (this.txSN.length === 0) {
            toast('请先获取验证码')
            return
        }

        if (this.code.length === 0) {
            toast('请先输入验证码')
            return
        }
        if (!this.checked) {
            toast('请先勾选协议')
            return
        }

        this.loading = true

        zjConfirmBindCard(this.txSN, this.code).then(value => {

            runInAction(() => {
                this.loading = false
                if (value.code === '00000') {
                    // toast('绑定成功')
                    navigate("/zj-success?name=" +
                        this.zjResponse!.result!.name +
                        "&idNo=" + this.zjResponse!.result!.idNo +
                        "&bankCardNumber=" + this.bankCard +
                        "&bankName=" + this.selectedBand!.bankName +
                        "&mobile=" + this.mobile, {
                        replace: true
                    })
                } else {
                    toast(value.message)
                }
            })
        })
    }


    async sendSmsCode(): Promise<BaseJsonResponse<string>> {
        if (this.bankCard.length === 0) {
            return {
                result: 'error',
                code: '10001',
                message: '请输入银行卡号'
            }
        }

        if (this.selectedBand === null) {
            return {
                result: 'error',
                code: '10002',
                message: '请选择开户银行'
            }
        }

        if (this.mobile.length !== 11) {
            return {
                result: 'error',
                code: '10003',
                message: '请输入正确的手机号'
            }
        }

        const response = await zjBindCard({
            credentialNumber: this.zjResponse?.result?.idNo ?? '',
            bankID: this.selectedBand!.bankCode,
            bankAccountNumber: this.bankCard,
            bankAccountName: this.zjResponse!.result!.name,
            bankPhoneNumber: this.mobile,
            customerName: this.zjResponse?.result?.name ?? '',
            bankName: this.selectedBand!.bankName

        }, this.zjResponse!.result!.customerNo)

        if (response.code === '00000') {
            this.txSN = response.result?.txSN ?? ''
        }

        return {
            result: 'ok',
            code: response.code,
            message: response.message
        }
    }

    selectedBandName() {
        if (this.selectedBand === null) {
            return ''
        }
        return this.selectedBand.bankName
    }

    dialogOpen: boolean = false

    searchBankKeyword: string = ''

    openBankListDialog() {
        this.dialogOpen = true
    }

    closeBankListDialog() {
        this.dialogOpen = false
    }


    protocolDialogOpen: boolean = false

    openProtocolDialog() {
        this.protocolDialogOpen = true
    }

    closeProtocolDialog() {
        this.protocolDialogOpen = false
    }

    constructor(id: string) {
        makeAutoObservable(this)
        this.loadData(id)

        zjBankList().then(result => {
            runInAction(() => {
                this.bankList = result.result ?? []
            })
        })
    }


    toggleChecked() {
        this.checked = !this.checked
    }

    loadData(id: string) {

        zjIndex(id).then(value => {
            runInAction(() => {
                this.zjResponse = value
            })
        })
    }
}


function bandListDialog(viewModel: ViewModel) {
    return <Dialog fullScreen open={viewModel.dialogOpen} onClose={() => {
        viewModel.closeBankListDialog()
    }
    }>
        <AppBar sx={{position: 'relative'}}>
            <Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={() => viewModel.closeBankListDialog()}
                    aria-label="close"
                >
                    <Close/>
                </IconButton>
                <input style={{
                    backgroundColor: 'transparent',
                    border: 'none',
                    fontSize: '5vw',
                    color: 'white',
                    flex: 1

                }} placeholder={'请输入银行名称'} className={style.search} onChange={(event) => {
                    viewModel.searchBankKeyword = event.target.value
                }}/>

                <Search/>

            </Toolbar>
        </AppBar>
        <List>
            {
                viewModel.bankList
                    .filter(value => value.bankName.includes(viewModel.searchBankKeyword))
                    .map((value) => {
                        return <List.Item key={value.bankCode} onClick={() => {
                            // viewModel.selectedBand = value
                            viewModel.updateSelectedBand(value)
                            viewModel.closeBankListDialog()
                        }}>{value.bankName}</List.Item>
                    })
            }
        </List>
    </Dialog>;
}

function ZjPage() {

    window.document.title = '绑定银行卡'


    const [params] = useSearchParams()
    const id = params.get('id') ?? ''

    const [viewModel] = useState(() => new ViewModel(id))

    const navigate = useNavigate()

    ///加载中
    if (viewModel.zjResponse === undefined) {
        return <div className={style.container}>
            <CircularProgress/>
        </div>
    }

    ///加载失败
    if (viewModel.zjResponse.result === null) {
        return <>
            <div className={style.container}>{viewModel.zjResponse.message}</div>

            {/*{bandListDialog(viewModel)}*/}


        </>
    }

    return <>
        <div className={style.root}>
            <div className={style.content}>


                <div className={style.row} style={{
                    // marginTop: '5vw'
                }}>
                    <div className={style.title}>账户姓名</div>
                    <input className={style.input}
                           readOnly={true}
                           value={viewModel.zjResponse.result.name}
                           style={{flexGrow: 1}}></input>
                </div>
                <div className={style.divider} style={{
                    marginTop: '5vw'
                }}/>
                <div className={style.row} style={{
                    marginTop: '5vw'
                }}>
                    <div className={style.title}>身份证号码</div>
                    <input className={style.input}
                           readOnly={true}
                           value={viewModel.zjResponse.result.idNo}
                           style={{flexGrow: 1}}></input>
                </div>


                <div className={style.divider} style={{
                    marginTop: '5vw'
                }}/>

                <div className={style.row} style={{
                    marginTop: '5vw'
                }} onClick={() => {
                    viewModel.openBankListDialog()
                }}>
                    <div className={style.title}>开户银行</div>
                    <input className={style.input} placeholder={'请选择开户银行'} inputMode={"text"}
                           style={{flexGrow: 1}} readOnly value={viewModel.selectedBandName()}></input>
                </div>

                <div className={style.divider} style={{
                    marginTop: '5vw'
                }}/>

                <div className={style.row} style={{
                    marginTop: '5vw'
                }}>
                    <div className={style.title}>银行卡账号</div>
                    <input className={style.input} placeholder={'请填写银行卡账号'} onChange={(event) => {
                        viewModel.bankCard = event.target.value
                    }} inputMode={"numeric"}
                           style={{flexGrow: 1}}></input>
                </div>

                <div className={style.divider} style={{
                    marginTop: '5vw'
                }}/>

                <div className={style.title} style={{
                    marginTop: '3vw'
                }}>手机号码
                </div>

                <input onChange={(event) => {
                    // viewModel.mobile = event.target.value
                    viewModel.updateMobile(event.target.value)
                }} className={app.phoneInput} inputMode={'tel'} placeholder={'请输入银行预留的手机号码'}/>

                <div className={app.codeContainer}>
                    <input onChange={(event) => {
                        // viewModel.code = event.target.value
                        // viewModel.updateMobile(event.target.value)
                        viewModel.updateCode(event.target.value)
                    }} className={app.codeInput} placeholder={'请输入验证码'} inputMode={'numeric'}/>

                    <GetCodeView phone={viewModel.mobile} variant={'contained'} method={async () => {
                        // let result = await applyBindBankCard({
                        //     userid: userId,
                        //     bankcard: viewModel.bankCard,
                        //     tel: viewModel.mobile
                        // }, userHeader)
                        //
                        // if (result.result === 'exist') {
                        //     //已经绑过卡了，直接完成
                        //     viewModel.showExistBackCardDialog()
                        // }
                        // return result

                        // return {
                        //     result: 'success',
                        //     code: '00000',
                        //     message: 'success',
                        // }

                        return viewModel.sendSmsCode()
                    }} callback={() => {
                        // viewModel.signSeqNo = value
                    }}/>
                </div>

                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: 16,
                    marginBottom: 0,
                    alignItems: 'center',
                    fontSize: 13
                }}>
                    <Checkbox onChange={() => {
                        viewModel.toggleChecked()
                    }} checked={viewModel.checked}/>
                    我已阅读并同意
                    <span style={{
                        color: 'blue'
                    }} onClick={() => {
                        viewModel.openProtocolDialog()

                    }}>《委托扣款授权书》</span>

                </div>


            </div>

            <div style={{flexGrow: 1}}/>

            <div style={{
                backgroundColor: 'white',
            }}>
                <Button style={{
                    width: '92vw',
                    margin: '4vw'
                }} variant={'contained'}
                        disabled={viewModel.loading}
                        onClick={() => {
                            // viewModel.showErrorDialogFunc()
                            // viewModel.commit(userId, userHeader).then()
                            viewModel.commit(navigate)
                        }}
                >提交</Button>
            </div>
        </div>

        {bandListDialog(viewModel)}

        <Dialog
            open={viewModel.protocolDialogOpen}
            onClose={() => {
                viewModel.closeProtocolDialog()
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"委托扣款授权书"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    您与广州市凯蒙科技有限公司已签署《服务合同》（以下简称“合同”），您现授权广州市凯蒙科技有限公司（即被授权人）按合同约定划扣相应的资费（包括您应付的服务费、其他费用等），现您已仔细阅读、理解下述各项规定并同意遵守，郑重声明如下：

                    <br/>
                    一、您同意被授权人在合同约定的限期内，委托银行或被授权人合作的第三方机构从本授权书指定的账户内以约定的资费标准划付应付的费用（包括但不限于服务费及其他费用）。
                    <br/>

                    二、您在指定账户中必须留有足够余额，否则因账户余额不足或不可归责于被授权人委托方的任何事由，导致无法及时扣款或扣款错误、失败，责任由您自行承担。
                    <br/>

                    三、合同效力中止或终止后，本授权书效力同时中止或终止，被授权人暂停或终止委托划付款项，合同效力恢复后，本授权书效力随即恢复。
                    <br/>

                    四、本授权书自您勾选已阅读同意之日起生效，至您书面通知终止授权、或授权账户注销、或合同效力终止时终止。
                    <br/>

                    五、您同意终止授权或变更账户、通讯地址时，在当期款项交付日30
                    个工作日前向被授权人递交书面通知，否则自行承担所造成的风险损失。
                    <br/>

                    六、您保证本授权书的真实性、合法性、有效性，被授权人依据本授权书进行的委托扣款操作引起的一切法律纠纷或风险，由您独立承担或解决。
                    <br/>

                    七、您授权的资料包括：银行卡户名、银行卡开户银行、银行卡账号、身份证号码、联系手机。
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    viewModel.closeProtocolDialog()
                }}>
                    确定
                </Button>
            </DialogActions>
        </Dialog>
    </>

}


// interface IdNamePair {
//     id: string,
//     name: string
// }
//
// //预授权支付银行列表
// const authPayBankList: Array<IdNamePair> = [
//     // {id: "700", name: "700-模拟银行"},
//     {id: "888", name: "888-中金网银无卡"},
//     {id: "889", name: "889-中金网银"},
//     {id: "891", name: "891-金科无卡"},
//     {id: "100", name: "100-邮储银行"},
//     {id: "102", name: "102-工商银行"},
//     {id: "103", name: "103-农业银行"},
//     {id: "104", name: "104-中国银行"},
//     {id: "105", name: "105-建设银行"},
//     {id: "301", name: "301-交通银行"},
//     {id: "302", name: "302-中信银行"},
//     {id: "303", name: "303-光大银行"},
//     {id: "304", name: "304-华夏银行"},
//     {id: "305", name: "305-民生银行"},
//     {id: "306", name: "306-广发银行"},
//     {id: "308", name: "308-招商银行"},
//     {id: "309", name: "309-兴业银行"},
//     {id: "310", name: "310-浦发银行"},
//     {id: "311", name: "311-恒丰银行"},
//     {id: "313", name: "313-华融湘江银行"},
//     {id: "316", name: "316-浙商银行"},
//     {id: "317", name: "317-渤海银行"},
//     {id: "321", name: "321-重庆三峡银行"},
//     {id: "401", name: "401-上海银行"},
//     {id: "402", name: "402-厦门银行"},
//     {id: "403", name: "403-北京银行"},
//     {id: "405", name: "405-福建海峡银行"},
//     {id: "408", name: "408-宁波银行"},
//     {id: "409", name: "409-齐鲁银行"},
//     {id: "412", name: "412-温州银行"},
//     {id: "413", name: "413-广州银行"},
//     {id: "417", name: "417-盛京银行"},
//     {id: "419", name: "419-辽阳银行"},
//     {id: "420", name: "420-大连银行"},
//     {id: "421", name: "421-苏州银行"},
//     {id: "422", name: "422-河北银行"},
//     {id: "423", name: "423-杭州银行"},
//     {id: "424", name: "424-南京银行"},
//     {id: "426", name: "426-金华银行"},
//     {id: "427", name: "427-新疆乌鲁木齐市商业银行"},
//     {id: "428", name: "428-绍兴银行"},
//     {id: "430", name: "430-抚顺银行"},
//     {id: "431", name: "431-临商银行"},
//     {id: "432", name: "432-湖北银行"},
//     {id: "433", name: "433-葫芦岛银行"},
//     {id: "434", name: "434-天津银行"},
//     {id: "435", name: "435-郑州银行"},
//     {id: "436", name: "436-宁夏银行"},
//     {id: "438", name: "438-齐商银行"},
//     {id: "439", name: "439-锦州银行"},
//     {id: "440", name: "440-徽商银行"},
//     {id: "442", name: "442-哈尔滨银行"},
//     {id: "443", name: "443-贵阳银行"},
//     {id: "447", name: "447-兰州银行"},
//     {id: "448", name: "448-南昌银行"},
//     {id: "451", name: "451-吉林银行"},
//     {id: "454", name: "454-九江银行"},
//     {id: "457", name: "457-秦皇岛市商业银行"},
//     {id: "458", name: "458-青海银行"},
//     {id: "459", name: "459-台州市商业银行"},
//     {id: "461", name: "461-长沙银行"},
//     {id: "462", name: "462-潍坊银行"},
//     {id: "463", name: "463-赣州银行"},
//     {id: "464", name: "464-泉州银行"},
//     {id: "450", name: "450-青岛银行"},
//     {id: "465", name: "465-营口银行"},
//     {id: "466", name: "466-富滇银行"},
//     {id: "470", name: "470-嘉兴银行"},
//     {id: "473", name: "473-浙江泰隆商业银行"},
//     {id: "474", name: "474-内蒙古银行"},
//     {id: "478", name: "478-广西北部湾银行"},
//     {id: "479", name: "479-包商银行"},
//     {id: "481", name: "481-威海市商业银行"},
//     {id: "483", name: "483-攀枝花商行"},
//     {id: "485", name: "485-绵阳市商业银行"},
//     {id: "488", name: "488-三门峡市商业银行"},
//     {id: "490", name: "490-张家口市商业银行"},
//     {id: "491", name: "491-桂林银行"},
//     {id: "495", name: "495-柳州银行"},
//     {id: "496", name: "496-南充市商业银行"},
//     {id: "497", name: "497-莱商银行"},
//     {id: "498", name: "498-德阳银行"},
//     {id: "500", name: "500-六盘水市商业银行"},
//     {id: "502", name: "502-曲靖市商业银行"},
//     {id: "701", name: "701-昆仑银行"},
//     {id: "1401", name: "1401-上海农商行"},
//     {id: "1402", name: "1402-昆山农村商业银行"},
//     {id: "1403", name: "1403-常熟农商银行"},
//     {id: "1404", name: "1404-深圳农村商业银行"},
//     {id: "1405", name: "1405-广州农村商业银行"},
//     {id: "1408", name: "1408-顺德农商银行"},
//     {id: "1410", name: "1410-湖北农信"},
//     {id: "1412", name: "1412-江阴农村商业银行"},
//     {id: "1413", name: "1413-重庆农村商业银行"},
//     {id: "1414", name: "1414-山东农信"},
//     {id: "1415", name: "1415-东莞农村商业银行"},
//     {id: "1416", name: "1416-张家港农村商业银行"},
//     {id: "1418", name: "1418-北京农村商业银行"},
//     {id: "1420", name: "1420-宁波鄞州农村合作银行"},
//     {id: "1424", name: "1424-江苏省农村信用社联合社"},
//     {id: "1428", name: "1428-吴江农村商业银行"},
//     {id: "1433", name: "1433-太仓农村商业银行"},
//     {id: "1434", name: "1434-临汾市尧都市农村信用联社"},
//     {id: "1437", name: "1437-无锡农商行"},
//     {id: "1438", name: "1438-湖南农村信用社"},
//     {id: "1439", name: "1439-江西农信"},
//     {id: "1442", name: "1442-陕西省农村信用社联合社"},
//     {id: "1501", name: "1501-江苏银行"},
//     {id: "1502", name: "1502-邯郸市商业银行"},
//     {id: "1503", name: "1503-邢台银行"},
//     {id: "1504", name: "1504-承德银行"},
//     {id: "1505", name: "1505-沧州银行"},
//     {id: "1506", name: "1506-晋城市商业银行"},
//     {id: "1507", name: "1507-鄂尔多斯银行"},
//     {id: "1508", name: "1508-上饶银行"},
//     {id: "1509", name: "1509-东营市商业银行"},
//     {id: "1511", name: "1511-泰安市商业银行"},
//     {id: "1514", name: "1514-漯河商行"},
//     {id: "1515", name: "1515-商丘市商业银行"},
//     {id: "1517", name: "1517-浙江民泰商业银行"},
//     {id: "1518", name: "1518-龙江银行"},
//     {id: "1519", name: "1519-浙江稠州商业银行"},
//     {id: "1520", name: "1520-安徽省农村信用社联合社"},
//     {id: "1521", name: "1521-广西壮族农村信用社联合社"},
//     {id: "1522", name: "1522-海南省农村信用社"},
//     {id: "1523", name: "1523-云南省农村信用联社"},
//     {id: "1524", name: "1524-宁夏黄河农村商业银行"},
//     {id: "1526", name: "1526-安阳市商业银行"},
//     {id: "1527", name: "1527-保定市商业银行"},
//     {id: "1528", name: "1528-成都农商银行"},
//     {id: "1529", name: "1529-广东省农村信用社"},
//     {id: "1530", name: "1530-河北省农村信用联合社"},
//     {id: "1531", name: "1531-鹤壁银行"},
//     {id: "1532", name: "1532-衡水市商业银行"},
//     {id: "1534", name: "1534-晋中市商业商行"},
//     {id: "1535", name: "1535-库尔勒市商业银行"},
//     {id: "1536", name: "1536-乐山市商业银行"},
//     {id: "1537", name: "1537-凉山州商业银行"},
//     {id: "1538", name: "1538-内蒙古农信"},
//     {id: "1539", name: "1539-山西省农村信用社联合社"},
//     {id: "1540", name: "1540-深圳福田银座村镇银行"},
//     {id: "1541", name: "1541-遂宁市商业银行"},
//     {id: "1542", name: "1542-唐山市商业银行"},
//     {id: "1543", name: "1543-天津滨海银行"},
//     {id: "1544", name: "1544-乌海银行"},
//     {id: "1546", name: "1546-许昌银行"},
//     {id: "1547", name: "1547-雅安市商业银行"},
//     {id: "1548", name: "1548-阳泉市商业银行"},
//     {id: "1549", name: "1549-宜宾市商业银行"},
//     {id: "1550", name: "1550-玉溪市商业银行"},
//     {id: "1551", name: "1551-周口市商业银行"},
//     {id: "1552", name: "1552-自贡市商业银行"},
//     {id: "1553", name: "1553-遵义市商业银行"},
//     {id: "1555", name: "1555-长安银行"},
//     {id: "1562", name: "1562-吉林省农村信用合作社"},
//     {id: "1565", name: "1565-颖淮农村商业银行"},
//     {id: "1569", name: "1569-贵州银行"},
//     {id: "1570", name: "1570-长治商行"},
//     {id: "1571", name: "1571-朝阳银行"},
//     {id: "1572", name: "1572-江苏江南农村商业银行"},
//     {id: "1573", name: "1573-宁波东海银行"},
//     {id: "1574", name: "1574-平顶山银行"},
//     {id: "1575", name: "1575-青海省农村信用社联合社"},
//     {id: "1576", name: "1576-四川省农村信用合作社"},
//     {id: "1577", name: "1577-铁岭商业银行"},
//     {id: "1578", name: "1578-武汉农村商业银行"},
//     {id: "1579", name: "1579-信阳银行"},
//     {id: "1585", name: "1585-新疆农村信用社"},
//     {id: "1615", name: "1615-天津金城银行"},
//     {id: "1629", name: "1629-驻马店银行"},
//     {id: "1633", name: "1663-沈阳农村商业银行"},
//     {id: "1734", name: "1734-石家庄汇融农村合作银行"},
//     {id: "1747", name: "1747-四川新网银行股份有限公司"},
//     {id: "1833", name: "1833-陕西秦农农村商业银行"},
//     {id: "1731", name: "1731-重庆富民银行"},
//     {id: "1744", name: "1744-杭州联合农村商业银行"},
//     {id: "1766", name: "1766-梅州客商银行"},
//     {id: "1767", name: "1767-苏宁银行"},
//     {id: "1839", name: "1839-辽宁振兴银行"},
//     {id: "3001", name: "3001-东亚银行"},
//     {id: "3007", name: "3007-星展银行"},
//     {id: "3010", name: "3010-花旗银行"},
//     {id: "3034", name: "3034-渣打银行（中国）"},
//     {id: "3036", name: "3036-法国兴业银行（中国）"}
// ]


export default observer(ZjPage)
