import header from "../../assets/images/download_header.png";
import logo from '../../assets/images/download_logo.png';
import button from '../../assets/images/download_button.png';
import {isAndroid, isIOS, isWeChatBrowser} from "../../Util";

export const handleDownload = () => {
    if (isAndroid) {
        window.location.href = "https://www.kaimengkeji.com/app/kaimeng.apk";
    } else if (isIOS) {
        window.location.href = 'https://apps.apple.com/cn/app/%E5%87%AF%E8%92%99%E4%BA%91%E6%9C%8D/id6451387112';
    }
}

export default function DownloadAppPage() {
    window.document.title = '下载凯蒙APP'


    return (
        <div style={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        }}>
            {
                isWeChatBrowser() && <img style={{width: '100%'}} src={header} alt={''}/>
            }

            <div style={{
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <img src={logo} style={{
                    width: '12.5rem'
                }} alt={''}/>
            </div>


            <img src={button}
                 onClick={handleDownload}
                 alt={''}
                 style={{
                     width: '70%',
                     marginBottom: '8vh'
                 }}/>

        </div>
    );
}
